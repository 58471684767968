import { createRouter, createWebHistory } from 'vue-router';
import gamesPage from '@/views/GamesPage.vue';
import gamesAll from '@/components/GamesAll.vue';
import gameDice from '@/components/GameDice.vue';
import gameMines from '@/components/GameMines.vue';
import gameHorse from '@/components/GameHorse.vue';
import FAQ from '@/views/FAQ.vue';
import replenishPage from '@/views/Replenishment.vue';
import withdrawalPage from '@/views/Withdrawal.vue';
import bonusPage from '@/views/Bonus.vue';
import referalPage from '@/views/Referal.vue';
import gameRoulette from '@/components/GameRoulette.vue';
import settingsPage from '@/views/Settings.vue';
import privacyPolicy from '@/views/PrivacyPolicy.vue';
import roule from '@/views/RouleView.vue';
const routes = [
    {
        path: '/',
        name: 'home',
        component: gamesPage,
        children: [
            {
                path: '',
                component: gamesAll
            },
            {
                path: 'dice',
                component: gameDice
            },
            {
                path: 'mines',
                component: gameMines
            },
            {
                path: 'horses',
                component: gameHorse
            },
            {
                path: 'roulette',
                component: gameRoulette
            }
        ]
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ
    },
    {
        path: '/replenish',
        name: 'replenish',
        component: replenishPage
    },
    {
        path: '/withdrawal',
        name: 'withdrawal',
        component: withdrawalPage
    },
    {
        path: '/bonus',
        name: 'bonus',
        component: bonusPage
    },
    {
        path: '/referal',
        name: 'referal',
        component: referalPage
    },
    {
        path: '/privacypolicy',
        name: 'privacypolicy',
        component: privacyPolicy
    },
    {
        path: '/settings',
        name: 'settings',
        component: settingsPage
    },
    {
        path: '/roule',
        name: 'roule',
        component: roule
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
