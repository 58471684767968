import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "games" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_game_changer = _resolveComponent("game-changer");
    const _component_router_view = _resolveComponent("router-view");
    const _component_game_chat = _resolveComponent("game-chat");
    const _component_best_score = _resolveComponent("best-score");
    const _component_last_games = _resolveComponent("last-games");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("section", _hoisted_1, [
            (!_ctx.$appAttr.isPhone)
                ? (_openBlock(), _createBlock(_component_game_changer, { key: 0 }))
                : _createCommentVNode("", true),
            _createVNode(_component_router_view),
            (_ctx.$route.path !== '/horses' && _ctx.$route.path !== '/roulette')
                ? (_openBlock(), _createBlock(_component_game_chat, { key: 1 }))
                : _createCommentVNode("", true)
        ]),
        (_ctx.$route.path === '/')
            ? (_openBlock(), _createBlock(_component_best_score, { key: 0 }))
            : _createCommentVNode("", true),
        _createVNode(_component_last_games)
    ], 64));
}
